import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Processos que realmente funcionam',
    paragraph: 'Orientados ao modelo e visão de negócio de cada parceiro, desenvolvemos as soluções conforme necessidade e realidade de cada contexto. Transformando as ferramentas em extensões naturais dos processos já abituais ou mais convenientes para cada organização.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Fluxos de ágeis com riqueza e qualidade de informações
                  </div>
                <h3 className="mt-0 mb-12">
                  Data-driven insights
                  </h3>
                <p className="m-0">
                  Primeiro o essencial, ciência, soluções e decisões pautadas em informações solidas e consistentes, baseadas em dados abrangente e de alta qualidade. Não se faz planejamento sem testes, e não se faz testes sem ciência, logo dados são nossas unidades mais preciosas.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Fluxos simples e intuitivos
                  </div>
                <h3 className="mt-0 mb-12">
                  Desenvolvimento com foco na Experiência do Usuário
                  </h3>
                <p className="m-0">
                  Segundo a conveniência, não desenvolvemos soluções irreais. Imaginamos e tornamos reais. Realmente usáveis, com real valor. Assim nosso maior decisor é quem vai usar a ferramenta no dia-a-dia, tornando nossas necessidades de analise, algo de valor para quem lida com os dados no mundo real.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Soluções de baixo custo com alto valor agregado
                  </div>
                <h3 className="mt-0 mb-12">
                  Projeto e desenvolvimento visando o Retorno sobre os Investimentos
                  </h3>
                <p className="m-0">
                  Não perdemos de vista por nem um minuto se quer a realidade do mundo. E nossos investimentos, seja de tempo, seja financeiro, devem gerar resultados sólidos e verificáveis. Mostrando que nossa metologia ágil, que preza a entraga excelente e rápida, frente a entrega perfeita e fora de timming, traz a sensação de controle e segurança do rumo das ações e decições.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;